import React, { useRef } from 'react';

import { Container } from '@hpstellar/core';

import withPageComponents from '../../../page/components/with-page-components';
import Topseller from '../../../shared/components/topseller';

import withEinstein from '../../../einstein/withEinstein';
import { useIsPrivateStore } from '../../../hooks/useUserData';
import ABTest from '../../../abtest/components/abtest';
import useDynamicYieldRecs from '../../../hooks/useDynamicYieldRecs';

import './home-popular-products.less';

const HomePopularProducts = ({ vanityUrl, strategyId }) => {
    const widgetRef = useRef(null);
    const { productRecs: products, dyContainerTracking } = useDynamicYieldRecs(
        strategyId,
        vanityUrl,
        { maxProducts: 8 },
        true,
        widgetRef,
    );

    return (
        <Container className="homePopularProductsDY" {...(dyContainerTracking || {})}>
            <HomeTopSeller vanityUrl={vanityUrl} products={products} isDY />
        </Container>
    );
};

const HomePopularProductsEinstein = withEinstein(
    withPageComponents(
        ({ homePopularProducts, vanityUrl, einsteinProducts }) => {
            const products =
                einsteinProducts && einsteinProducts.length > 0
                    ? einsteinProducts
                    : homePopularProducts && homePopularProducts.products;

            return <HomeTopSeller vanityUrl={vanityUrl} products={einsteinProducts || products} />;
        },
        { components: ['homePopularProducts'] },
    ),
    'homePopularProducts',
);

const HomeTopSeller = ({ vanityUrl, products, ...rest }) => {
    const carouselSettings = {
        className: 'home-popular-products-carousel',
        slidesToShow: 5,
        slidesToScroll: 1,
        dots: true,
        showDotsCounter: true,
        arrows: true,
        infinite: true,
        equalHeight: true,
        responsive: [
            {
                breakpoint: 1919,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 1365,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    draggable: true,
                    slidesToShow: 1.4,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
        ],
    };

    return (
        <Topseller
            products={products}
            title="You may also like"
            carouselSettings={carouselSettings}
            className="home-popular-products"
            productTileClassName="home-popular-card"
            list="home-popularproduct"
            vanityUrl={vanityUrl}
            componentKey="homePopularProducts"
            linkTarget="_self"
            priceProps={{ size: 'large' }}
            {...rest}
        />
    );
};

export default () => {
    const isPrivateStore = useIsPrivateStore();

    if (isPrivateStore) {
        return <HomePopularProductsEinstein />;
    }

    return (
        <ABTest experimentID="homePageRecommendations">
            {({ strategyId = 289842 }) => <HomePopularProducts vanityUrl="/" strategyId={strategyId} />}
        </ABTest>
    );
};
